import { getURLStringParams } from "../url";

const urlParams = getURLStringParams(window.location.search);

export const saml = urlParams?.get("samlContext");
export const response_type = urlParams?.get("response_type");
export const client_id = urlParams?.get("client_id");
export const scope = urlParams?.get("scope");
export const redirect_uri = xssCheck(urlParams?.get("redirect_uri"));
export const preferred_duration = urlParams?.get("preferred_duration");
export const minimum_duration = urlParams?.get("minimum_duration");
export const state = urlParams?.get("state");


export const queryParams = {
    response_type: response_type,
    client_id: client_id,
    scope: scope,
    redirect_uri: redirect_uri,
    preferred_duration: preferred_duration,
    minimum_duration: minimum_duration,
    state: state
}

function xssCheck(param) {
    let uri = param?.toLowerCase();
    if(!uri?.startsWith("https://")){
        return "undefined";
    }
    else {
        return param;
    }
    
}